import React, { useState, useEffect } from "react"
import StaticImage from "gatsby-theme-yum-components/src/components/atoms/Images/StaticImage"
import StoryblokImage from "gatsby-theme-yum-components/src/components/atoms/Images/StoryblokImage"

const Image = ({ image, className, style, fixedSize, aspectRatio }) => {
  const [imageType, setImageType] = useState("")

  useEffect(() => {
    if (
      typeof image === "object" &&
      /storyblok/.test(image.filename) &&
      /^.*\.(?!svg)[^.]+$/
    ) {
      setImageType("sbImage")
    } else {
      setImageType("staticImage")
    }
  }, [])

  const IMAGE_TYPES = {
    sbImage: (
      <StoryblokImage
        image={image}
        title={image.title}
        alt={image.alt}
        copyright={image.copyright}
        className={className}
        style={style}
        fixedSize={fixedSize}
        aspectRatio={aspectRatio}
      />
    ),
    staticImage: (
      <StaticImage
        image={image.filename ? image.filename : image}
        title={image.title}
        alt={image.alt}
        copyright={image.copyright}
        className={className}
        style={style}
      />
    ),
  }

  const ImageState = ({ imageType }) => {
    return <>{IMAGE_TYPES[imageType]}</>
  }

  return (
    <>
      <ImageState imageType={imageType} />
    </>
  )
}

export default Image
