import React from "react"

const StaticImage = ({ image, className, style, title, alt }) => {
  if (!image || image === undefined) {
    return <></>
  }
  const imageSrc = image

  return (
    <img
      loading="lazy"
      src={imageSrc}
      alt={alt}
      className={className}
      title={title ? title : alt}
      style={style}
    />
  )
}

export default StaticImage
