import React, { useState, useLayoutEffect, useRef } from "react"

const StoryblokImage = ({
  image,
  className,
  style,
  fixedSize,
  aspectRatio,
  title,
  alt,
}) => {
  const [containerHeight, setContainerHeight] = useState(null)
  const [containerWidth, setContainerWidth] = useState(null)
  const [imageSize, setImageSize] = useState(null)
  const [heightInPercentage, setHeightInPercentage] = useState(null)
  const [loading, setLoading] = useState(true)
  const containerRef = useRef(null)

  const getImageSize = () => {
    const width = containerRef.current.clientWidth
    setContainerWidth(Math.ceil(width / 50) * 50)
    if (aspectRatio && aspectRatio !== "") {
      const [ratioWidth, ratioHeight] = aspectRatio.split("by")
      const height = (containerWidth / ratioWidth) * ratioHeight
      const percentage = (100 * height) / containerWidth
      setContainerHeight(height.toFixed(0))
      setImageSize(`${containerWidth}x${containerHeight}`)
      setHeightInPercentage(percentage)
      setLoading(false)
    } else {
      setImageSize(`${containerWidth}x0`)
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    if (containerRef.current) {
      getImageSize()
    }
  }, [containerRef, imageSize, loading])

  if (!image || !image.filename) {
    return <></>
  }

  !image && ""
  let originalSrc = image.filename
    ? image.filename
    : image.src
    ? image.src
    : image.length > 0 && image

  let imageService = "https://img2.storyblok.com"
  let fileName = originalSrc.replace("https://a.storyblok.com/", "")
  let focus = image.focus ? `filters:focal(${image.focus})` : "smart"

  let imageSrc = ""

  if (!loading && fixedSize) {
    imageSrc = `${imageService}/${fixedSize}/${focus}/${fileName}`
  } else if (!loading && !fixedSize) {
    imageSrc = `${imageService}/${imageSize}/${focus}/${fileName}`
  }

  return (
    <div
      ref={containerRef}
      style={
        aspectRatio && aspectRatio !== ""
          ? { height: `${heightInPercentage}%` }
          : { height: "" }
      }
      className={`relative ${className}`}
    >
      {aspectRatio && aspectRatio !== "" && (
        <div
          className="w-full"
          style={{ paddingBottom: `${heightInPercentage}%` }}
        ></div>
      )}
      {!loading ? (
        <img
          loading="lazy"
          src={imageSrc}
          alt={alt}
          className={`${
            aspectRatio && aspectRatio !== "" ? "absolute top-0" : ""
          } ${className}`}
          title={title ? title : alt}
          style={style}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default StoryblokImage
