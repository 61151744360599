import logoBfsp from "../../images/logo-bfsp.png"
import logoKam from "../../images/logo-kam.png"
import logoTam from "../../images/logo-tam-black.png"
import logoOak from "../../images/logo-oak.png"
import logoStadtbibliothek from "../../images/logo-stadtbibliothek-pad-black.jpg"
import logoStadthalle from "../../images/logo-vilco.jpg"

const ThemeLogo = (theming) => {
  const themeHeader = {
    burgfestspiele: { logo: logoBfsp },
    "kino-alte-muehle": { logo: logoKam },
    "theater-alte-muehle": { logo: logoTam },
    "open-air-kino": { logo: logoOak },
    stadtbibliothek: { logo: logoStadtbibliothek },
    vilco: { logo: logoStadthalle },
    "kultur-und-natur": { text: "Kultur & Natur" },
    kulturforum: { text: "Kulturforum" },
    museen: { text: "Museen" },
    quellenfest: { text: "Quellenfest" },
    "feste-und-maerkte": { text: "Fest & Märkte" },
    none: { text: "" },
  }

  const theme = themeHeader[theming]
  const themeLogo = theme ? theme["logo"] : ""
  const themeText = theme ? theme["text"] : ""

  return [themeLogo, themeText]
}

export default ThemeLogo
